.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 44, 52, 0.9);
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .rotate {
    animation: Goat infinite 5s alternate linear;
  }
}

.goat-party {
  z-index: 1;
  cursor: pointer;
  height: 3em;
  width: 8em;
  border: none;
  border-radius: 0.2em;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, 0.4);
}

@keyframes Goat {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}
